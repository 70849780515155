
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import * as StatServices from '@/services/StatServices'


@Component
export default class BounceRateChart extends Vue {
    @Prop({ required: true }) from!: Date;
    @Prop({ required: true }) to!: Date;

    chartOptions = {
        chart: {
            id: 'vistors'
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
        },
        dataLabels: {
            enabled: false
        },
    }

    series = [] as any[]

    loading = false;

    @Watch('from', { immediate: true })
    @Watch('to', { immediate: true })
    async onDateChange() {
        this.loading = true;
        const data = await StatServices.GetAverageSessionDuration(this.from, this.to);
        this.series = [{
            name: 'Average session time (seconds)',
            data: data.map((e: any) => [new Date(e.date).getTime(), e.averageSessionDuration.toFixed(2)])
        }]
        this.loading = false;
    }

    get average() {
        console.log(this.series)
        if (!this.series.length) return 0;
        const serie = this.series[0];
        // data is an array of [date, value] pairs
        // value is a string, so we need to convert it to a number
        const values = serie.data.map((e: any) => parseFloat(e[1]));
        if (values.length > 0) {
            const sum = values.reduce((a: number, b: number) => a + b);
            return (sum / values.length).toFixed(2);
        }
        return 0;
    }
}
